import React from 'react';
import './index.css';
import $ from 'jquery';
import { isMobile } from 'react-device-detect';

var age = "ALL";
var interest = "ALL";
var area = "ALL";

var results = "";
var fontSize = '';
var shownCamps = 0;
var numCamps = 0;
var k = 0;
var openDate;
var applicableCamps = [];

var camps = ["coding", "coding2", "flash", "ByteApp", "clay", "3D", "3Dgame", "2Dtab", "musicvid", "3Dprint", "ByteUEng", "ByteUDev", "designHE", "design", "3DM", "flash4G", "foundAI", "ByteUHack"];
var campNames = ["Introduction to Coding", "Introduction to Coding Level 2", "2D Video Game Design", "Build an App", "Claymation Movie Production", "3D Animation", "3D Video Game Design", "2D Animation on Tablet", "Music and Video Production", "3D Printing & Design", "Byte Camp U - Engineering", "Byte Camp U - Game Dev", "Graphic Design & Printing", "Graphic Design & Printing", "3D Modeling", "Girls Only 2D Video Games", "Foundations in AI", "Byte Camp U - Ethical Hackers"];
var campColors = ["#bee4ffff", "#bee4ffff", "#ffff96ff", "#e3ff93ff", "#ffb8b7ff", "#b4ffb4ff", "#f6c0ffff", "#ffebddff", "#bee4ffff", "#e7bda2ff", "rgb(181, 238, 235)", "#be3c4f", "#84f9ac", "#84f9ac", "#b4ffb4ff", "#ffff96ff", "#f4bd8f", "#cbe39c"];

class Register extends React.Component{

    sendToCalendar(){
        window.open('./calendar/index.html');
    }

    async componentDidMount(){

        async function getCamps() {

            const response = await fetch('./calendar/camps.json');
            const allCamps = await response.json();
            //console.log(allCamps[0][0].length);
            return allCamps[0];

        }

        const camps2021 = await getCamps();
        //console.log(camps2021[1][0][4]);

        function setAge(value){
            age = value;
            searchCamps();
        }

        $('#PCage9Dropdown').click(function () {
            setAge('9-12');
            document.getElementById("PCagedropbtn").innerHTML = "Age: 9-10 &nbsp; &#x25BC;";
        })
        $('#PCageAllDropdown').click(function () {
            setAge('ALL');
            document.getElementById("PCagedropbtn").innerHTML = "Age: All &nbsp; &#x25BC;";
        })
        $('#PCageMidDropdown').click(function () {
            setAge('MID');
            document.getElementById("PCagedropbtn").innerHTML = "Age: 11-12 &nbsp; &#x25BC;";
        })
        $('#PCage11Dropdown').click(function () {
            setAge('11-14');
            document.getElementById("PCagedropbtn").innerHTML = "Age: 13-14 &nbsp; &#x25BC;";
        })
        $('#PCage15Dropdown').click(function () {
            setAge('13-17');
            document.getElementById("PCagedropbtn").innerHTML = "Age: 15-17 &nbsp; &#x25BC;";
        })

        $('#age9Dropdown').click(function () {
            setAge('9-12');
            document.getElementById("agedropbtn").innerHTML = "Age: 9-10 &nbsp; &#x25BC;";
        })
        $('#ageAllDropdown').click(function () {
            setAge('ALL');
            document.getElementById("agedropbtn").innerHTML = "Age: All &nbsp; &#x25BC;";
        })
        $('#ageMidDropdown').click(function () {
            setAge('MID');
            document.getElementById("agedropbtn").innerHTML = "Age: 11-12 &nbsp; &#x25BC;";
        })
        $('#age11Dropdown').click(function () {
            setAge('11-14');
            document.getElementById("agedropbtn").innerHTML = "Age: 13-14 &nbsp; &#x25BC;";
        })
        $('#age15Dropdown').click(function () {
            setAge('13-17');
            document.getElementById("agedropbtn").innerHTML = "Age: 15-17 &nbsp; &#x25BC;";
        })

        function setInterest(value){
            interest = value;
            searchCamps();
        }

        $('#PCintAllDropdown').click(function () {
            setInterest('ALL');
            document.getElementById("PCintdropbtn").innerHTML = "Interest: All &nbsp; &#x25BC;";
        })
        $('#PCintCodeDropdown').click(function () {
            setInterest('code');
            document.getElementById("PCintdropbtn").innerHTML = "Interest: Coding &nbsp; &#x25BC;";
        })
        $('#PCintAnimDropdown').click(function () {
            setInterest('anim');
            document.getElementById("PCintdropbtn").innerHTML = "Interest: Animation &nbsp; &#x25BC;";
        })
        $('#PCintDesignDropdown').click(function () {
            setInterest('design');
            document.getElementById("PCintdropbtn").innerHTML = "Interest: Graphic Design &nbsp; &#x25BC;";
        })
        $('#PCint3DDropdown').click(function () {
            setInterest('3D');
            document.getElementById("PCintdropbtn").innerHTML = "Interest: 3D Design &nbsp; &#x25BC;";
        })
        $('#PCintVidDropdown').click(function () {
            setInterest('video');
            document.getElementById("PCintdropbtn").innerHTML = "Interest: Video Editing &nbsp; &#x25BC;";
        })
        $('#PCintEngDropdown').click(function () {
            setInterest('eng');
            document.getElementById("PCintdropbtn").innerHTML = "Interest: Engineering &nbsp; &#x25BC;";
        })

        $('#intAllDropdown').click(function () {
            setInterest('ALL');
            document.getElementById("intdropbtn").innerHTML = "Interest: All &nbsp; &#x25BC;";
        })
        $('#intCodeDropdown').click(function () {
            setInterest('code');
            document.getElementById("intdropbtn").innerHTML = "Interest: Coding &nbsp; &#x25BC;";
        })
        $('#intAnimDropdown').click(function () {
            setInterest('anim');
            document.getElementById("intdropbtn").innerHTML = "Interest: Animation &nbsp; &#x25BC;";
        })
        $('#intDesignDropdown').click(function () {
            setInterest('design');
            document.getElementById("intdropbtn").innerHTML = "Interest: Graphic Design &nbsp; &#x25BC;";
        })
        $('#int3DDropdown').click(function () {
            setInterest('3D');
            document.getElementById("intdropbtn").innerHTML = "Interest: 3D Design &nbsp; &#x25BC;";
        })
        $('#intVidDropdown').click(function () {
            setInterest('video');
            document.getElementById("intdropbtn").innerHTML = "Interest: Video Editing &nbsp; &#x25BC;";
        })
        $('#intEngDropdown').click(function () {
            setInterest('eng');
            document.getElementById("intdropbtn").innerHTML = "Interest: Engineering &nbsp; &#x25BC;";
        })

        function setArea(value){
            area = value;
            console.log(area);
            searchCamps();
        }
        $('#PCregionAllDropdown').click(function () {
            setArea('ALL');
            document.getElementById("PCregiondropbtn").innerHTML = "Location: All &nbsp; &#x25BC;";
        })
        $('#PCregionSVIDropdown').click(function () {
            setArea('SVI');
            document.getElementById("PCregiondropbtn").innerHTML = "Location: Victoria &nbsp; &#x25BC;";
        })
        $('#PCregionCVIDropdown').click(function () {
            setArea("CVI");
            document.getElementById("PCregiondropbtn").innerHTML = "Location: Duncan/Nanaimo &nbsp; &#x25BC;";
        })
        $('#PCregionNVIDropdown').click(function () {
            setArea("NVI");
            document.getElementById("PCregiondropbtn").innerHTML = "Location: Comox/Campbell River &nbsp; &#x25BC;";
        })
        $('#PCregionMETDropdown').click(function () {
            setArea("MET");
            document.getElementById("PCregiondropbtn").innerHTML = "Location: Metro Vancouver &nbsp; &#x25BC;";
        })
        $('#PCregionVANDropdown').click(function () {
            setArea("VAN");
            document.getElementById("PCregiondropbtn").innerHTML = "Location: Vancouver &nbsp; &#x25BC;";
        })
        $('#PCregionNWVDropdown').click(function () {
            setArea("NWV");
            document.getElementById("PCregiondropbtn").innerHTML = "Location: North Shore &nbsp; &#x25BC;";
        })
        $('#PCregionBBYDropdown').click(function () {
            setArea("BBY");
            document.getElementById("PCregiondropbtn").innerHTML = "Location: Burnaby & Tri-Cities &nbsp; &#x25BC;";
        })
        $('#PCregionRCHDropdown').click(function () {
            setArea("RCH");
            document.getElementById("PCregiondropbtn").innerHTML = "Location: Richmond &nbsp; &#x25BC;";
        })
        $('#PCregionSRYDropdown').click(function () {
            setArea("SRY");
            document.getElementById("PCregiondropbtn").innerHTML = "Location: Surrey &nbsp; &#x25BC;";
        })

        $('#regionAllDropdown').click(function () {
            setArea('ALL');
            document.getElementById("regiondropbtn").innerHTML = "Location: All &nbsp; &#x25BC;";
        })
        $('#regionSVIDropdown').click(function () {
            setArea('SVI');
            document.getElementById("regiondropbtn").innerHTML = "Location: Victoria &nbsp; &#x25BC;";
        })
        $('#regionCVIDropdown').click(function () {
            setArea("CVI");
            document.getElementById("regiondropbtn").innerHTML = "Location: Duncan/Nanaimo &nbsp; &#x25BC;";
        })
        $('#regionNVIDropdown').click(function () {
            setArea("NVI");
            document.getElementById("regiondropbtn").innerHTML = "Location: Comox/Campbell River &nbsp; &#x25BC;";
        })
        $('#regionMETDropdown').click(function () {
            setArea("MET");
            document.getElementById("regiondropbtn").innerHTML = "Location: Metro Vancouver &nbsp; &#x25BC;";
        })
        $('#regionVANDropdown').click(function () {
            setArea("VAN");
            document.getElementById("regiondropbtn").innerHTML = "Location: Vancouver &nbsp; &#x25BC;";
        })
        $('#regionNWVDropdown').click(function () {
            setArea("NWV");
            document.getElementById("regiondropbtn").innerHTML = "Location: North Shore &nbsp; &#x25BC;";
        })
        $('#regionBBYDropdown').click(function () {
            setArea("BBY");
            document.getElementById("regiondropbtn").innerHTML = "Location: Burnaby & Tri-Cities &nbsp; &#x25BC;";
        })
        $('#regionRCHDropdown').click(function () {
            setArea("RCH");
            document.getElementById("regiondropbtn").innerHTML = "Location: Richmond &nbsp; &#x25BC;";
        })
        $('#regionSRYDropdown').click(function () {
            setArea("SRY");
            document.getElementById("regiondropbtn").innerHTML = "Location: Surrey &nbsp; &#x25BC;";
        })

        
            
        
          
        // Callback function for asynchronous call to HTML5 geolocation
        function UserLocation(position) {
            NearestCity(position.coords.latitude, position.coords.longitude);
        }
        
        // Convert Degress to Radians
        function Deg2Rad(deg) {
            return deg * Math.PI / 180;
        }
        
        function PythagorasEquirectangular(lat1, lon1, lat2, lon2) {
            lat1 = Deg2Rad(lat1);
            lat2 = Deg2Rad(lat2);
            lon1 = Deg2Rad(lon1);
            lon2 = Deg2Rad(lon2);
            var R = 6371; // km
            var x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
            var y = (lat2 - lat1);
            var d = Math.sqrt(x * x + y * y) * R;
            return d;
        }
        
        var cities = [
            ["SVI", 48.438838, -123.348525],
            ["CVI", 49.160260, -123.939487],
            ["VAN", 49.264191, -123.117068],
            ["NWV", 49.334731, -123.126185],
            ["BBY", 49.250921, -122.900776],
            ["RCH", 49.170205, -123.127381],
            ["SRY", 49.169738, -122.828004]
            ];
        
        function NearestCity(latitude, longitude) {
            var minDif = 99999;
            var closest;
            
            for (var index = 0; index < cities.length; ++index) {
                var dif = PythagorasEquirectangular(latitude, longitude, cities[index][1], cities[index][2]);
                if (dif < minDif) {
                closest = index;
                minDif = dif;
                }
            }
            
            // echo the nearest city
            console.log(cities[closest]);
            if (isMobile){
                $("#region"+ cities[closest][0] +"Dropdown").click();
            } else {
                $("#PCregion"+cities[closest][0] +"Dropdown").click();
            }
        }

        const months = ['0','Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug','Sept', 'Oct','Nov','Dec'];
        const curDate = new Date();

        var dateString = "";
        if ((Number(curDate.getMonth())+1) < 10) {
            if (curDate.getDate() < 10){
                dateString = curDate.getFullYear() + "-0" + (Number(curDate.getMonth())+1) + "-0" + curDate.getDate();
            } else {
                dateString = curDate.getFullYear() + "-0" + (Number(curDate.getMonth())+1) + "-" + curDate.getDate();
            }
        } else {
            if (curDate.getDate() < 10){
                dateString = curDate.getFullYear() + "-" + (Number(curDate.getMonth())+1) + "-0" + curDate.getDate();
            } else {
                dateString = curDate.getFullYear() + "-" + (Number(curDate.getMonth())+1) + "-" + curDate.getDate();
            } 
        }
        var actualDate = dateString;
        //var actualDate = "2022-08-01";
        /*if (Date.parse(dateString) < Date.parse('2022-06-26')) {
            console.log(dateString);
            dateString = '2022-06-26';
        }*/
        //dateString = "2021-07-18";

        function searchCamps(){
            document.getElementById("searchResults").innerHTML = camps2021[0].length;
            applicableCamps = [];
            var pos = 0;
            numCamps = 0;
            //console.log(camps2021[0].length);
            //console.log(Date.parse(dateString), Date.parse('2022-06-26'));
            //console.log(dateString, area, interest, age);
            for (var j = 0; j < 42; j++){
                for (var i = 1; i < camps2021.length; i++){
                    if (camps2021[i][j] !== undefined){
                        if (Date.parse(dateString) < Date.parse(camps2021[i][j][12])){
                            if (area === "ALL"){
                                camps2021[i][0][3] = camps2021[i][0][3].replace("Saanich Rec @", "");
                                if ("'"+age+"'" === camps2021[i][j][3] || age === "ALL"){
                                    if (interest === "ALL"){
                                        applicableCamps[pos] = camps2021[i][j];
                                        applicableCamps[pos][1] = camps2021[i][0][3];
                                        pos++;
                                        numCamps++;
                                    } else if (interest === "code"){
                                        if (camps2021[i][j][2] === "coding" || camps2021[i][j][2] === "flash" || camps2021[i][j][2] === "flash4G" || camps2021[i][j][2] === "ByteApp" || camps2021[i][j][2] === "3Dgame" || camps2021[i][j][2] === "foundAI" ){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        }
                                    }
                                    else if (interest === "anim"){
                                        if (camps2021[i][j][2] === "clay" || camps2021[i][j][2] === "3D" || camps2021[i][j][2] === "2Dtab"){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        }
                                    } else if (interest === "3D"){
                                        if (camps2021[i][j][2] === "3D" || camps2021[i][j][2] === "3DM" || camps2021[i][j][2] === "3Dgame" || camps2021[i][j][2] === "3Dprint"){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        }
                                    } else if (interest === "video"){
                                        if (camps2021[i][j][2] === "musicvid"){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        }
                                    } 
                                    else if (interest === "design"){
                                        if (camps2021[i][j][2] === "design" || camps2021[i][j][2] === "designHE"){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        }
                                    } 
                                }else if (age === "MID" && ("'9-12'" === camps2021[i][j][3] || "'11-14'" === camps2021[i][j][3])){
                                    if (interest === "ALL"){
                                        applicableCamps[pos] = camps2021[i][j];
                                        applicableCamps[pos][1] = camps2021[i][0][3];
                                        pos++;
                                        numCamps++;
                                    } else if (interest === "code"){
                                        if (camps2021[i][j][2] === "coding" || camps2021[i][j][2] === "flash" || camps2021[i][j][2] === "flash4G" || camps2021[i][j][2] === "ByteApp" || camps2021[i][j][2] === "3Dgame" || camps2021[i][j][2] === "coding2" || camps2021[i][j][2] === "foundAI"){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        }
                                    }
                                    else if (interest === "anim"){
                                        if (camps2021[i][j][2] === "clay" || camps2021[i][j][2] === "3D" || camps2021[i][j][2] === "2Dtab"){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        }
                                    } else if (interest === "3D"){
                                        if (camps2021[i][j][2] === "3D" || camps2021[i][j][2] === "3DM" || camps2021[i][j][2] === "3Dgame" || camps2021[i][j][2] === "3Dprint"){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        }
                                    } 
                                    else if (interest === "video"){
                                        if (camps2021[i][j][2] === "musicvid"){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        }
                                    }
                                    else if (interest === "design"){
                                        if (camps2021[i][j][2] === "design" || camps2021[i][j][2] === "designHE" ){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        }
                                    }
                                }
                            }
                            else if (area === "MET"){ //If the whole of Metro Vancouver is selected, show all Metro Vancouver camps
                                if (camps2021[i][j][4] === "VAN" || camps2021[i][j][4] ==="NWV" || camps2021[i][j][4] === "BBY" || camps2021[i][j][4] === "RCH" || camps2021[i][j][4] === "SRY"){
                                    if ("'"+age+"'" === camps2021[i][j][3] || age === "ALL"){
                                        if (interest === "ALL"){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        } else if (interest === "code"){
                                            if (camps2021[i][j][2] === "coding" || camps2021[i][j][2] === "flash" || camps2021[i][j][2] === "flash4G" || camps2021[i][j][2] === "ByteApp" || camps2021[i][j][2] === "3Dgame" || camps2021[i][j][2] === "coding2" || camps2021[i][j][2] === "foundAI"){
                                                applicableCamps[pos] = camps2021[i][j];
                                                applicableCamps[pos][1] = camps2021[i][0][3];
                                                pos++;
                                                numCamps++;
                                            }
                                        }
                                        else if (interest === "anim"){
                                            if (camps2021[i][j][2] === "clay" || camps2021[i][j][2] === "3D" || camps2021[i][j][2] === "2Dtab"){
                                                applicableCamps[pos] = camps2021[i][j];
                                                applicableCamps[pos][1] = camps2021[i][0][3];
                                                pos++;
                                                numCamps++;
                                            }
                                        } else if (interest === "3D"){
                                            if (camps2021[i][j][2] === "3D" || camps2021[i][j][2] === "3DM" || camps2021[i][j][2] === "3Dgame" || camps2021[i][j][2] === "3Dprint"){
                                                applicableCamps[pos] = camps2021[i][j];
                                                applicableCamps[pos][1] = camps2021[i][0][3];
                                                pos++;
                                                numCamps++;
                                            }
                                        } 
                                        else if (interest === "video"){
                                            if (camps2021[i][j][2] === "musicvid"){
                                                applicableCamps[pos] = camps2021[i][j];
                                                applicableCamps[pos][1] = camps2021[i][0][3];
                                                pos++;
                                                numCamps++;
                                            }
                                        }
                                        else if (interest === "design"){
                                            if (camps2021[i][j][2] === "design" || camps2021[i][j][2] === "designHE"){
                                                applicableCamps[pos] = camps2021[i][j];
                                                applicableCamps[pos][1] = camps2021[i][0][3];
                                                pos++;
                                                numCamps++;
                                            }
                                        }
                                    } else if (age === "MID" && ("'9-12'" === camps2021[i][j][3] || "'11-14'" === camps2021[i][j][3])){
                                        if (interest === "ALL"){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        } else if (interest === "code"){
                                            if (camps2021[i][j][2] === "coding" || camps2021[i][j][2] === "flash" || camps2021[i][j][2] === "flash4G" || camps2021[i][j][2] === "ByteApp" || camps2021[i][j][2] === "3Dgame" || camps2021[i][j][2] === "coding2" || camps2021[i][j][2] === "foundAI"){
                                                applicableCamps[pos] = camps2021[i][j];
                                                applicableCamps[pos][1] = camps2021[i][0][3];
                                                pos++;
                                                numCamps++;
                                            }
                                        }
                                        else if (interest === "anim"){
                                            if (camps2021[i][j][2] === "clay" || camps2021[i][j][2] === "3D" || camps2021[i][j][2] === "2Dtab"){
                                                applicableCamps[pos] = camps2021[i][j];
                                                applicableCamps[pos][1] = camps2021[i][0][3];
                                                pos++;
                                                numCamps++;
                                            }
                                        } else if (interest === "3D"){
                                            if (camps2021[i][j][2] === "3D" || camps2021[i][j][2] === "3DM" || camps2021[i][j][2] === "3Dgame" || camps2021[i][j][2] === "3Dprint"){
                                                applicableCamps[pos] = camps2021[i][j];
                                                applicableCamps[pos][1] = camps2021[i][0][3];
                                                pos++;
                                                numCamps++;
                                            }
                                        } 
                                        else if (interest === "video"){
                                            if (camps2021[i][j][2] === "musicvid"){
                                                applicableCamps[pos] = camps2021[i][j];
                                                applicableCamps[pos][1] = camps2021[i][0][3];
                                                pos++;
                                                numCamps++;
                                            }
                                        }
                                        else if (interest === "design"){
                                            if (camps2021[i][j][2] === "design" || camps2021[i][j][2] === "designHE"){
                                                applicableCamps[pos] = camps2021[i][j];
                                                applicableCamps[pos][1] = camps2021[i][0][3];
                                                pos++;
                                                numCamps++;
                                            }
                                        }
                                    }
                                }
                            } else if (area === camps2021[i][j][4]){
                                console.log(camps2021[i][j][4], age);
                                camps2021[i][0][3] = camps2021[i][0][3].replace("Saanich Rec @", "");
                                if ("'"+age+"'" === camps2021[i][j][3] || age === "ALL"){
                                    if (interest === "ALL"){
                                        applicableCamps[pos] = camps2021[i][j];
                                        applicableCamps[pos][1] = camps2021[i][0][3];
                                        pos++;
                                        numCamps++;
                                    } else if (interest === "code"){
                                        if (camps2021[i][j][2] === "coding" || camps2021[i][j][2] === "flash" || camps2021[i][j][2] === "flash4G" || camps2021[i][j][2] === "ByteApp" || camps2021[i][j][2] === "3Dgame" || camps2021[i][j][2] === "coding2" || camps2021[i][j][2] === "foundAI"){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        }
                                    }
                                    else if (interest === "anim"){
                                        if (camps2021[i][j][2] === "clay" || camps2021[i][j][2] === "3D" || camps2021[i][j][2] === "2Dtab"){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        }
                                    } else if (interest === "3D"){
                                        if (camps2021[i][j][2] === "3D" || camps2021[i][j][2] === "3DM" || camps2021[i][j][2] === "3Dgame" || camps2021[i][j][2] === "3Dprint"){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        }
                                    } else if (interest === "video"){
                                        if (camps2021[i][j][2] === "musicvid"){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        }
                                    } 
                                    else if (interest === "design"){
                                        if (camps2021[i][j][2] === "design" || camps2021[i][j][2] === "designHE"){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        }
                                    } 
                                } else if (age === "MID" && ("'9-12'" === camps2021[i][j][3] || "'11-14'" === camps2021[i][j][3])){
                                    if (interest === "ALL"){
                                        applicableCamps[pos] = camps2021[i][j];
                                        applicableCamps[pos][1] = camps2021[i][0][3];
                                        pos++;
                                        numCamps++;
                                    } else if (interest === "code"){
                                        if (camps2021[i][j][2] === "coding" || camps2021[i][j][2] === "flash" || camps2021[i][j][2] === "flash4G" || camps2021[i][j][2] === "ByteApp" || camps2021[i][j][2] === "3Dgame" || camps2021[i][j][2] === "coding2" || camps2021[i][j][2] === "foundAI"){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        }
                                    }
                                    else if (interest === "anim"){
                                        if (camps2021[i][j][2] === "clay" || camps2021[i][j][2] === "3D" || camps2021[i][j][2] === "2Dtab"){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        }
                                    } else if (interest === "3D"){
                                        if (camps2021[i][j][2] === "3D" || camps2021[i][j][2] === "3DM" || camps2021[i][j][2] === "3Dgame" || camps2021[i][j][2] === "3Dprint"){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        }
                                    } 
                                    else if (interest === "video"){
                                        if (camps2021[i][j][2] === "musicvid"){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        }
                                    }
                                    else if (interest === "design"){
                                        if (camps2021[i][j][2] === "design" || camps2021[i][j][2] === "designHE"){
                                            applicableCamps[pos] = camps2021[i][j];
                                            applicableCamps[pos][1] = camps2021[i][0][3];
                                            pos++;
                                            numCamps++;
                                        }
                                    }
                                }
                            }                             
                            if ((area !== "SVI" && area !== "ALL") || (age !== "ALL" && age !== "13-17")){
                                if (age !== "9-12" && age !== "MID"){
                                    if ("'11-14'" === camps2021[i][j][3] || "'13-17'" === camps2021[i][j][3]){
                                        if (interest === "ALL" || interest === "eng" || interest === "code" || interest === "3D"){
                                            if (camps2021[i][j][2] === "ByteUEng" || camps2021[i][j][2] === "ByteUDev" || camps2021[i][j][2] === "ByteUHack"){
                                                applicableCamps[pos] = camps2021[i][j];
                                                applicableCamps[pos][1] = camps2021[i][0][3];
                                                pos++;
                                                numCamps++;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            applicableCamps.sort(sortFunction);

            function sortFunction(a, b){
                if (a[12] === b[12]){
                    return 0;
                } else {
                    return (a[12] < b[12]) ? -1 : 1;
                }
            }

            console.log(applicableCamps);
            
            if (isMobile){
                fontSize = "large";
            } else {
                fontSize = "x-large";
            }
            //console.log(applicableCamps);
            if (numCamps === 0){
                if ((curDate.getMonth()+1 >=3  && curDate.getMonth()+1 <= 9)){ //After March 1st if no camps available for their selection
                    document.getElementById("searchResults").innerHTML = "<div class='noCamps'><b>We don't seem to be offering any camps for your current selections. Please try a different combination of Age, Interest, and Location. Have questions about our camps and age ranges?<br/><br/><a onClick=\"document.getElementById('contactus').click()\" id='fullCalendarButton'>Contact Us</a></b></div><br/>";
                } else if (curDate.getMonth()+1 > 8 ){ //After Sept 1st if no camps available for their selection
                    // document.getElementById("searchResults").innerHTML = "<div class='noCamps'><b>Looking for summer 2024? We are working on it! New camps & new locations! Details will publish March 1st. <br/><br/> <a href=\"mailto:alix@bytecamp.ca?&subject=Can't%20wait%20to%20see%20your%20summer%20schedule!&body=Hi%20Alix,%0D%0A%0D%0ACongratulations%20on%2020%20years%20of%20Byte%20Camp!😊%20Can%20you%20please%20add%20this%20email%20address%20to%20your%20sneak%20peek%20calendar%20mail%20out%20in%20late%20February?%20And%20a%20reminder%20for%20registrations%20when%20they%20open%20on%20March%201st.%0D%0A%0D%0AThank%20you!%0D%0A%0D%0A\" id='emailButton'>Email us</a> for a sneak peek in February!</b></div><br/>";
                    document.getElementById("searchResults").innerHTML = "<div class='noCamps'><b>Our summer camps for " + curDate.getFullYear() + " have all wrapped up and we can't wait to bring you more programs! Keep an eye out here for After-School, Evening, and Pro-D Day camps in Victoria and Vancouver coming soon! <br/><br/> Reach out now to discuss more opportunities or to ask about running programs at your school<br/><br/><a onClick=\"document.getElementById('contactus').click()\" id='fullCalendarButton'>Contact Us</a></b></div><br/>";
                    document.getElementById("fullCalendar").style.display = "none"
                    document.getElementById("fullCalendarButtonMobile").style.display = "none"
                } else { //Before March 1st as summer camps haven't been posted yet
                    //document.getElementById("searchResults").innerHTML = "<div class='noCamps'><b>Looking for summer 2024? We are working on it! New camps & new locations! Details will publish March 1st. <br/><br/> <a href=\"mailto:alix@bytecamp.ca?&subject=Can't%20wait%20to%20see%20your%20summer%20schedule!&body=Hi%20Alix,%0D%0A%0D%0ACongratulations%20on%2020%20years%20of%20Byte%20Camp!😊%20Can%20you%20please%20add%20this%20email%20address%20to%20your%20sneak%20peek%20calendar%20mail%20out%20in%20late%20February?%20And%20a%20reminder%20for%20registrations%20when%20they%20open%20on%20March%201st.%0D%0A%0D%0AThank%20you!%0D%0A%0D%0A\" id='emailButton'>Email us</a> for a sneak peek in February!</b></div><br/>";
                    document.getElementById("searchResults").innerHTML = "<div class='noCamps'><b>Our summer camp guide for " + curDate.getFullYear() + " will be available March 1st. Want to bring Byte Camp to your school or recreation centre?<br/><br/><a onClick=\"document.getElementById('contactus').click()\" id='fullCalendarButton'>Contact Us</a></b></div><br/>";
                    document.getElementById("fullCalendar").style.display = "none"
                    document.getElementById("fullCalendarButtonMobile").style.display = "none"
                }
                document.getElementById('showMoreBtn').style.display = "none";
            } else {
                if (curDate.getMonth()+1 >= 9 || curDate.getMonth()+1 < 3){ // Hide summer calendar button from September to February
                    document.getElementById("fullCalendar").style.display = "none"
                    document.getElementById("fullCalendarButtonMobile").style.display = "none"
                }
                results = '';
                if (numCamps > 5){ //If more than 5 camps available with the current selections
                    shownCamps = 5;
                } else {
                    shownCamps = numCamps;
                }
                for (k = 0; k < shownCamps; k++){
                    
                    if (numCamps === 1){
                        results += "<div class='foundCamp' style='left:50%;transform:translateX(-50%);margin-left:0;background-color:white; border:4px solid; border-color:" + campColors[camps.indexOf(applicableCamps[k][2])] + "'>";
                    } else {
                        results += "<div class='foundCamp' style='background-color:white; border:8px solid; border-color:" + campColors[camps.indexOf(applicableCamps[k][2])] + "'>";
                    }
                    results += "<div class='foundCampInfo'><div id='foundCampInfo'><img class='campIcon' src='./imgs/CampIcons/" + applicableCamps[k][2] + ".png'/><div class='camp'><div class='campTitle'>" + applicableCamps[k][1] + "<br><span style='color:#24117bff; font-family: Rubik; font-size:"+fontSize+";'>" + campNames[camps.indexOf(applicableCamps[k][2])] + "</span></div><br>";
                    
                    //Need to change how it shows dates to show Month and start day and end day instead of grabbing from an array
                    var startDate = new Date(applicableCamps[k][12]);
                    var endDate = new Date(applicableCamps[k][13]);
                    var startMonth = startDate.toLocaleString('default', { month: 'long' });
                    var startDay = startDate.getDate()+1;
                    var endDay = endDate.getDate()+1;
                    var year = startDate.getFullYear();
                    results += "<div class='campInfo'>" + applicableCamps[k][3].substr(1).slice(0, -1) + "yrs&nbsp;&nbsp;&nbsp;" + startMonth + " " + startDay + "-" + endDay + " " + year + " <br>";
                    
                    if (applicableCamps[k][10]-3 > applicableCamps[k][11]){
                        results += (applicableCamps[k][10]-applicableCamps[k][11]) + " Seats remaining";
                    } else if (applicableCamps[k][10] > applicableCamps[k][11]){
                        results += "<b>Almost Full</b>";
                    } else {
                        results += "FULL";
                    }
                    
                    results += "</div><br><div class='registerNow'><a class='registerText' target='_blank' style='background-color:" + campColors[camps.indexOf(applicableCamps[k][2])] + "' href='https://www.bytecamp.ca/calendar/programdetail.php?pid=" + applicableCamps[k][6] + "'>More Info</a>&nbsp;&nbsp;&nbsp;";
                    if (Date.parse(applicableCamps[k][16]) > Date.parse(actualDate) && applicableCamps[k][17] !== "1") {
                        openDate = applicableCamps[k][16].split("-");
                        results += "<span class='regOpen'>Register On " + months[parseInt(openDate[1])] + " " + parseInt(openDate[2]) + "</span></div></div></div></div></div>";
                    } else {
                        results += "<a class='registerText' target='_blank' style='background-color:" + campColors[camps.indexOf(applicableCamps[k][2])] + "' href='";
                        if (applicableCamps[k][15] !== null && applicableCamps[k][15] !== ''){
                            results += applicableCamps[k][15];
                        } else {
                            results += "https://www.bytecamp.ca/calendar/programdetail.php?pid=" + applicableCamps[k][6];
                        }
                        if (applicableCamps[k][10] > applicableCamps[k][11]){
                            results += "'>Register Now</a></div></div></div></div></div>";
                        } else {
                            results += "'>Join Waitlist</a></div></div></div></div></div>";
                        }
                    }
                    
                }
                document.getElementById("searchResults").innerHTML = results;
                console.log("Number of camps is: ", numCamps);
                if (numCamps > 5){
                    document.getElementById('showMoreBtn').style.display = "block";
                }
                else {
                    //document.getElementById("searchResults").innerHTML += "<br/><div class='endFoundCamp'><br/><b>Looking for summer 2024? We are working on it! New camps & new locations! Details will publish March 1st. <br/><br/> <a href=\"mailto:alix@bytecamp.ca?&subject=Can't%20wait%20to%20see%20your%20summer%20schedule!&body=Hi%20Alix,%0D%0A%0D%0ACongratulations%20on%2020%20years%20of%20Byte%20Camp!😊%20Can%20you%20please%20add%20this%20email%20address%20to%20your%20sneak%20peek%20calendar%20mail%20out%20in%20late%20February?%20And%20a%20reminder%20for%20registrations%20when%20they%20open%20on%20March%201st.%0D%0A%0D%0AThank%20you!%0D%0A%0D%0A\" id='emailButton'>Email us</a> for a sneak peek in February!</b></div><br/>";
                    document.getElementById('showMoreBtn').style.display = "none";
                    document.getElementById("searchResults").innerHTML += "<br/><div class='endFoundCamp'><br/><b>Don't see something for you or want to bring Byte Camp to your School or Recreation Centre? <br/><br/><a onClick=\"document.getElementById('contactus').click()\" id='fullCalendarButton'>Contact Us</a></b></div><br>"
                }
            }

        }

        function showMoreCamps() {
            results = "";
            document.getElementById('showMoreBtn').style.display = "none";
            if (shownCamps+5 < numCamps){
                shownCamps += 5;
            } else {
                shownCamps = numCamps;
            }
            for (k; k < shownCamps; k++){
                
                results += "<div class='foundCamp' style='background-color:white; border:8px solid; border-color:" + campColors[camps.indexOf(applicableCamps[k][2])] + "'><div class='foundCampInfo'><div id='foundCampInfo'><img class='campIcon' src='./imgs/CampIcons/" + applicableCamps[k][2] + ".png'/><div class='camp'><div class='campTitle'>" + applicableCamps[k][1] + "<br><span style='color:#24117bff; font-family: Rubik; font-size:"+fontSize+";'>" + campNames[camps.indexOf(applicableCamps[k][2])] + "</span></div><br>";
                var startDate = new Date(applicableCamps[k][12]);
                var endDate = new Date(applicableCamps[k][13]);
                var startMonth = startDate.toLocaleString('default', { month: 'long' });
                var startDay = startDate.getDate()+1;
                var endDay = endDate.getDate()+1;
                var year = startDate.getFullYear();
                results += "<div class='campInfo'>" + applicableCamps[k][3].substr(1).slice(0, -1) + "yrs&nbsp;&nbsp;&nbsp;" + startMonth + " " + startDay + "-" + endDay + " " + year + " <br>";
                
                if (applicableCamps[k][10]-3 > applicableCamps[k][11]){
                    results += (applicableCamps[k][10]-applicableCamps[k][11]) + " Seats remaining";
                } else if (applicableCamps[k][10] > applicableCamps[k][11]){
                    results += "<b>Almost Full</b>";
                } else {
                    results += "FULL";
                }
                
                results += "</div><br><div class='registerNow'><a class='registerText' target='_blank' style='background-color:" + campColors[camps.indexOf(applicableCamps[k][2])] + "' href='https://www.bytecamp.ca/calendar/programdetail.php?pid=" + applicableCamps[k][6] + "'>More Info</a>&nbsp;&nbsp;&nbsp;";
                if (Date.parse(applicableCamps[k][16]) > Date.parse(actualDate) && applicableCamps[k][17] !== "1") {
                    openDate = applicableCamps[k][16].split("-");
                    results += "<span class='regOpen'>Register On " + months[parseInt(openDate[1])] + " " + parseInt(openDate[2]) + "</span></div></div></div></div></div>";
                } else {
                    results += "<a class='registerText' target='_blank' style='background-color:" + campColors[camps.indexOf(applicableCamps[k][2])] + "' href='";
                    if (applicableCamps[k][15] !== null && applicableCamps[k][15] !== ''){
                        results += applicableCamps[k][15];
                    } else {
                        results += "https://www.bytecamp.ca/calendar/programdetail.php?pid=" + applicableCamps[k][6];
                    }
                    if (applicableCamps[k][10] > applicableCamps[k][11]){
                        results += "'>Register Now</a></div></div></div></div></div>";
                    } else {
                        results += "'>Join Waitlist</a></div></div></div></div></div>";
                    }
                }
                
            }
            document.getElementById("searchResults").innerHTML += results;
            if (numCamps > shownCamps){
                document.getElementById('showMoreBtn').style.display = "block";
            }
            else {
                //document.getElementById("searchResults").innerHTML += "<br/><div class='endFoundCamp'><br/><b>Looking for summer 2024? We are working on it! New camps & new locations! Details will publish March 1st. <br/><br/> <a href=\"mailto:alix@bytecamp.ca?&subject=Can't%20wait%20to%20see%20your%20summer%20schedule!&body=Hi%20Alix,%0D%0A%0D%0ACongratulations%20on%2020%20years%20of%20Byte%20Camp!😊%20Can%20you%20please%20add%20this%20email%20address%20to%20your%20sneak%20peek%20calendar%20mail%20out%20in%20late%20February?%20And%20a%20reminder%20for%20registrations%20when%20they%20open%20on%20March%201st.%0D%0A%0D%0AThank%20you!%0D%0A%0D%0A\" id='emailButton'>Email us</a> for a sneak peek in February!</b></div><br/>";
                document.getElementById("searchResults").innerHTML += "<br/><div class='endFoundCamp'><br/><b>Don't see something for you or want to bring Byte Camp to your School or Recreation Centre? <br/><br/><a onClick=\"document.getElementById('contactus').click()\" id='fullCalendarButton'>Contact Us</a></b></div><br>"
            }
        }

        searchCamps();

        $('#moreBtn').click(function(){
            showMoreCamps();
        })

        window.onclick = function(event) {
            if (!event.target.matches('.dropbtn')) {
              var dropdowns = document.getElementsByClassName("dropdown-content");
              var i;
              for (i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show')) {
                  openDropdown.classList.remove('show');
                }
              }
            }
        }

        var params = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value){
            params[key] = value;
        })
        if (params.loc !== undefined){
            if (params.loc.length > 0){
                var urlLocation = params.loc.slice(0, -2);
                console.log(urlLocation);
                if (isMobile){
                    $("#region"+ urlLocation +"Dropdown").click();
                } else {
                    $("#PCregion"+urlLocation +"Dropdown").click();
                }
            }
        } else {

            // HTML5/W3C Geolocation
            if (navigator.geolocation && isMobile) {
                navigator.geolocation.getCurrentPosition(UserLocation);
            } else if (!isMobile){
                var ipAdd = "";
                $.getJSON("https://api.ipify.org?format=json", function(data) {
                    // Setting text of element P with id gfg
                    ipAdd = data.ip;
                    console.log(ipAdd);
                })
                var geoLink = "https://ipapi.co/" + ipAdd + "/json/"
                var getLoc = $.getJSON(geoLink);
                getLoc.done (function(location) {
                    console.log(location.latitude, location.longitude);
                    NearestCity(location.latitude, location.longitude);
                });
                getLoc.fail(function() {
                    if (isMobile){
                        $("#regionALLDropdown").click();
                    } else {
                        $("#PCregionALLDropdown").click();
                    }
                })
            } else { // Default to ALL if location is not traceable
                if (isMobile){
                    $("#regionAllDropdown").click();
                } else {
                    $("#PCregionAllDropdown").click();
                }
            }
        }

    }

    showPCAgeOptions(){
        document.getElementById("PCageDropdown").classList.toggle("show");
    }
    showPCIntOptions(){
        document.getElementById("PCintDropdown").classList.toggle("show");
    }
    showPCRegionOptions(){
        document.getElementById("PCregionDropdown").classList.toggle("show");
    }

    showAgeOptions(){
        document.getElementById("ageDropdown").classList.toggle("show");
    }
    showIntOptions(){
        document.getElementById("intDropdown").classList.toggle("show");
    }
    showRegionOptions(){
        document.getElementById("regionDropdown").classList.toggle("show");
    }

    render(){
        return (
            <div id="registerContent">
                <div id="searchCamps"><div class="filters">
                <div class="dropdown" style={{marginLeft: '0px'}}>
                    <button onClick={this.showPCAgeOptions} id="PCagedropbtn" class="dropbtn">Age: All &nbsp; &#x25BC;</button>
                    <div id="PCageDropdown" class="dropdown-content">
                        <a href="#r" id="PCageAllDropdown">All</a>
                        <a href="#r" id="PCage9Dropdown">9-10</a>
                        <a href="#r" id="PCageMidDropdown">11-12</a>
                        <a href="#r" id="PCage11Dropdown">13-14</a>
                        <a href="#r" id="PCage15Dropdown">15-17</a>
                    </div>
                </div>
                <div class="dropdown">
                    <button onClick={this.showPCIntOptions} id="PCintdropbtn" class="dropbtn">Interest: All &nbsp; &#x25BC;</button>
                    <div id="PCintDropdown" class="dropdown-content">
                        <a href="#r" id="PCintAllDropdown">All</a>
                        <a href="#r" id="PCintCodeDropdown">Coding</a>
                        <a href="#r" id="PCintAnimDropdown">Animation</a>
                        <a href="#r" id="PCintDesignDropdown">Graphic Design</a>
                        <a href="#r" id="PCint3DDropdown">3D Design</a>
                        <a href="#r" id="PCintVidDropdown">Video Editing</a>
                        <a href="#r" id="PCintEngDropdown">Engineering</a>
                    </div>
                </div>
                <div class="dropdown">
                    <button onClick={this.showPCRegionOptions} id="PCregiondropbtn" class="dropbtn">Location: All &nbsp; &#x25BC;</button>
                    <div id="PCregionDropdown" class="dropdown-content">
                        <a href="#r" id="PCregionAllDropdown">All</a>
                        <a href="#r" id="PCregionSVIDropdown">Victoria</a>
                        <a href="#r" id="PCregionCVIDropdown">Duncan/Nanaimo</a>
                        <a href="#r" id="PCregionNVIDropdown">Comox/Campbell River</a>
                        <a href="#r" id="PCregionMETDropdown">Metro Vancouver</a>
                        <a href="#r" id="PCregionVANDropdown">Vancouver</a>
                        <a href="#r" id="PCregionNWVDropdown">North Shore</a>
                        <a href="#r" id="PCregionBBYDropdown">Burnaby & Tri-Cities</a>
                        <a href="#r" id="PCregionRCHDropdown">Richmond</a>
                        <a href="#r" id="PCregionSRYDropdown">Surrey</a>
                    </div>
                </div>
                </div></div>
                <div id="searchResultBox">
                    <span id="mobileFilters"><div id="ageOptions" class="dropdown">
                        <br/><br/>
                        <button onClick={this.showAgeOptions} id="agedropbtn" class="dropbtn">Age: All &nbsp; &#x25BC;</button>
                        <div id="ageDropdown" class="dropdown-content">
                            <a href="#r" id="ageAllDropdown">All</a>
                            <a href="#r" id="age9Dropdown">9-10</a>
                            <a href="#r" id="ageMidDropdown">11-12</a>
                            <a href="#r" id="age11Dropdown">13-14</a>
                            <a href="#r" id="age15Dropdown">15-17</a>
                        </div>
                    </div><br/>
                    <div id="intOptions" class="dropdown">
                        <button onClick={this.showIntOptions} id="intdropbtn" class="dropbtn">Interest: All &nbsp; &#x25BC;</button>
                        <div id="intDropdown" class="dropdown-content">
                            <a href="#r" id="intAllDropdown">All</a>
                            <a href="#r" id="intCodeDropdown">Coding</a>
                            <a href="#r" id="intAnimDropdown">Animation</a>
                            <a href="#r" id="intDesignDropdown">Graphic Design</a>
                            <a href="#r" id="int3DDropdown">3D Design</a>
                            <a href="#r" id="intVidDropdown">Video Editing</a>
                            <a href="#r" id="intEngDropdown">Engineering</a>
                        </div>
                    </div><br/>
                    <div id="regionOptions" class="dropdown" style={{marginBottom: '10px'}}>
                        <button onClick={this.showRegionOptions} id="regiondropbtn" class="dropbtn">Location: All &nbsp; &#x25BC;</button>
                        <div id="regionDropdown" class="dropdown-content">
                            <a href="#r" id="regionAllDropdown">All</a>
                            <a href="#r" id="regionSVIDropdown">Victoria</a>
                            <a href="#r" id="regionCVIDropdown">Duncan/Nanaimo</a>
                            <a href="#r" id="regionNVIDropdown">Comox/Campbell River</a>
                            <a href="#r" id="regionMETDropdown">Metro Vancouver</a>
                            <a href="#r" id="regionVANDropdown">Vancouver</a>
                            <a href="#r" id="regionNWVDropdown">North Shore</a>
                            <a href="#r" id="regionBBYDropdown">Burnaby & Tri-Cities</a>
                            <a href="#r" id="regionRCHDropdown">Richmond</a>
                            <a href="#r" id="regionSRYDropdown">Surrey</a>
                        </div>
                    </div></span>
                    <div id="searchResults"></div>
                    <div id='showMoreBtn' class='moreBtnCont'><br/><a href="#r" id='moreBtn'>See More</a></div>
                    <div id="calendarBtnMobile" class='foundCamp' style={{backgroundColor:"white",textAlign:"center"}}><button id='fullCalendarButtonMobile' onClick={this.sendToCalendar}>Full Summer Schedule</button></div>
                </div>
                <div id="fullCalendar"><button id='fullCalendarButton' onClick={this.sendToCalendar}>Full Summer Schedule</button></div>
            </div>
        );
    }
};

export default Register;